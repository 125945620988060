@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-RegularItalic.f734b351.eot");
  src: local(Corporate S Pro Italic), local(CorporateSPro-RegularItalic), url("CorporateSPro-RegularItalic.f734b351.eot#iefix") format("embedded-opentype"), url("CorporateSPro-RegularItalic.343498a0.woff2") format("woff2"), url("CorporateSPro-RegularItalic.d5f32416.woff") format("woff"), url("CorporateSPro-RegularItalic.ba7803af.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-Regular.c2200460.eot");
  src: local(Corporate S Pro), local(CorporateSPro-Regular), url("CorporateSPro-Regular.c2200460.eot#iefix") format("embedded-opentype"), url("CorporateSPro-Regular.23d73dc6.woff2") format("woff2"), url("CorporateSPro-Regular.74de54cb.woff") format("woff"), url("CorporateSPro-Regular.0db78b57.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-Light.7b0fcd2c.eot");
  src: local(Corporate S Pro Light), local(CorporateSPro-Light), url("CorporateSPro-Light.7b0fcd2c.eot#iefix") format("embedded-opentype"), url("CorporateSPro-Light.09faeb38.woff2") format("woff2"), url("CorporateSPro-Light.46fa1011.woff") format("woff"), url("CorporateSPro-Light.76cd18c0.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-ExtraBold.4490a4d4.eot");
  src: local(Corporate S Pro ExtraBold), local(CorporateSPro-ExtraBold), url("CorporateSPro-ExtraBold.4490a4d4.eot#iefix") format("embedded-opentype"), url("CorporateSPro-ExtraBold.a67ce3b6.woff2") format("woff2"), url("CorporateSPro-ExtraBold.53fb695e.woff") format("woff"), url("CorporateSPro-ExtraBold.3aae3e4c.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-Bold.55b9eeed.eot");
  src: local(Corporate S Pro Bold), local(CorporateSPro-Bold), url("CorporateSPro-Bold.55b9eeed.eot#iefix") format("embedded-opentype"), url("CorporateSPro-Bold.1f990c66.woff2") format("woff2"), url("CorporateSPro-Bold.246f2eff.woff") format("woff"), url("CorporateSPro-Bold.8fcd79f9.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-Medium.95168b9a.eot");
  src: local(Corporate S Pro Medium), local(CorporateSPro-Medium), url("CorporateSPro-Medium.95168b9a.eot#iefix") format("embedded-opentype"), url("CorporateSPro-Medium.36bd8a74.woff2") format("woff2"), url("CorporateSPro-Medium.a9941443.woff") format("woff"), url("CorporateSPro-Medium.b627fbec.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Corporate S Pro Demi;
  src: url("CorporateSPro-DemiItalic.304cc2a1.eot");
  src: local(Corporate S Pro Demi Italic), local(CorporateSPro-DemiItalic), url("CorporateSPro-DemiItalic.304cc2a1.eot#iefix") format("embedded-opentype"), url("CorporateSPro-DemiItalic.df01cdbd.woff2") format("woff2"), url("CorporateSPro-DemiItalic.27035824.woff") format("woff"), url("CorporateSPro-DemiItalic.46f0a4ac.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Corporate S Pro Demi;
  src: url("CorporateSPro-Demi.7e4a8d5b.eot");
  src: local(Corporate S Pro Demi), local(CorporateSPro-Demi), url("CorporateSPro-Demi.7e4a8d5b.eot#iefix") format("embedded-opentype"), url("CorporateSPro-Demi.725fe907.woff2") format("woff2"), url("CorporateSPro-Demi.53fce852.woff") format("woff"), url("CorporateSPro-Demi.4c2527b3.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-ExtraBoldItalic.e0d9837c.eot");
  src: local(Corporate S Pro ExtraBold Italic), local(CorporateSPro-ExtraBoldItalic), url("CorporateSPro-ExtraBoldItalic.e0d9837c.eot#iefix") format("embedded-opentype"), url("CorporateSPro-ExtraBoldItalic.bd144ace.woff2") format("woff2"), url("CorporateSPro-ExtraBoldItalic.d173d1e7.woff") format("woff"), url("CorporateSPro-ExtraBoldItalic.656b210d.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-BoldItalic.fdaf34fe.eot");
  src: local(Corporate S Pro Bold Italic), local(CorporateSPro-BoldItalic), url("CorporateSPro-BoldItalic.fdaf34fe.eot#iefix") format("embedded-opentype"), url("CorporateSPro-BoldItalic.46a65dd9.woff2") format("woff2"), url("CorporateSPro-BoldItalic.5654622a.woff") format("woff"), url("CorporateSPro-BoldItalic.0f98068f.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-LightItalic.7bb13739.eot");
  src: local(Corporate S Pro Light Italic), local(CorporateSPro-LightItalic), url("CorporateSPro-LightItalic.7bb13739.eot#iefix") format("embedded-opentype"), url("CorporateSPro-LightItalic.f7b530fa.woff2") format("woff2"), url("CorporateSPro-LightItalic.3e0b810c.woff") format("woff"), url("CorporateSPro-LightItalic.5b19b8e4.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Corporate S Pro;
  src: url("CorporateSPro-MediumItalic.2f4cef88.eot");
  src: local(Corporate S Pro Medium Italic), local(CorporateSPro-MediumItalic), url("CorporateSPro-MediumItalic.2f4cef88.eot#iefix") format("embedded-opentype"), url("CorporateSPro-MediumItalic.1fcfe5a7.woff2") format("woff2"), url("CorporateSPro-MediumItalic.9a1b3656.woff") format("woff"), url("CorporateSPro-MediumItalic.ac4c1a30.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/*# sourceMappingURL=index.4ea3080c.css.map */
